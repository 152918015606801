import React from 'react';
import logo from './images/zeus-logo.svg';
import { Container, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import github from './images/github.svg';
import telegram from './images/telegram.svg';
import x from './images/x.svg';
import nostr from './images/nostr.png';

import canary from './images/canary.png';

function Footer() {
    return (
        <div className="App-footer">
            <Navbar bg="dark" variant="dark" expand="lg">
                <Container>
                    <Link to="/">
                        <Navbar.Brand>
                            <img
                                src={logo}
                                width="300"
                                height="55"
                                className="d-inline-block align-top"
                                alt="ZEUS"
                                style={{ marginBottom: 30 }}
                            />
                        </Navbar.Brand>
                    </Link>
                    <div className="footer-content">
                        © 2025 Atlas 21 Inc. All rights reserved
                        <ul className="footer-list">
                            <li>
                                <a href="/privacy-policy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="mailto:support@zeusln.com">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div className="social">
                        <a
                            href="https://github.com/ZeusLN/zeus"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={github}
                                height="55"
                                className="d-inline-block align-top"
                                alt="GitHub"
                            />
                        </a>
                        <a
                            href="https://x.com/ZeusLN"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={x}
                                height="55"
                                className="d-inline-block align-top"
                                alt="X"
                            />
                        </a>
                        <a
                            href="https://primal.net/zeus"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={nostr}
                                height="55"
                                className="d-inline-block align-top"
                                alt="Nostr"
                            />
                        </a>
                        <a
                            href="https://t.me/ZeusLN"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={telegram}
                                height="55"
                                className="d-inline-block align-top"
                                alt="Telegram"
                            />
                        </a>
                    </div>
                </Container>
            </Navbar>
            <div className="canary">
                <img
                    src={canary}
                    height="25"
                    className="d-inline-block align-top"
                    alt="Warrant canary"
                />
            </div>
        </div>
    );
}

export default Footer;
